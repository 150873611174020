.work{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.work-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin: 24px 0px;
}