.skill-card {
    width: max-content;
    padding: 8px 16px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6px;
    border-radius: 6px;
    box-shadow: 0px 0px 0px -5px;
    transition: 0.3s;
    animation: ease-in;
    cursor: pointer;
}
.skill-card:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 5px 0px;
}
.skill-icon{
    font-size: 80px;
}
.skill-name{
    font-size: 18px;
}
@media only screen and (max-width:720px){
    .skill-card{
        width: max-content;
        height: 100px;
    }
    .skill-icon{
        font-size: 60px;
    }
}