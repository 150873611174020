.header{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 12px;
    padding-top: 24px;
    position: relative;
    align-items: center;
}
.menu{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.menu-icon{
    font-size: 24px;
}
.web-menu{
    display: flex;
}
.logo{
    display: flex;
    font-size: 36px;
    color: blueviolet;
    font-weight: 800;
}
@media only screen and (max-width: 720px){  
    .logo{
        font-size: 24px;
    }
}