.projects{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.section-title {
    display: flex;
    align-items: center;
}
.cards-container-wrapper{
    display: flex;
    justify-content: space-evenly;
    margin-top: 36px;
}
@media only screen and (max-width: 720px){
    .cards-container-wrapper{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}