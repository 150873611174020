.contact{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.section-title{
    font-size: 32px;
    font-weight: 700;
}
.contact-container{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;

}
.contact-left{
    max-width: 60%;
    font-size: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.contact-left p{
    margin-bottom: 8px;
}
.contact-left a {
    text-decoration: none;
    color: black;
}
.download{
    padding: 8px 16px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px;
}
.download a{
    text-decoration: none;
    color: black;
}
@media only screen and (max-width:720px ) {
    .contact-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-left{
        max-width: 100%;
        font-size: 16px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .download{
        font-size: 16px;
    }
}