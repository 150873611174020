.work-card{
    padding: 8px;
    width: 45%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    box-shadow: 0px 0px 15px -5px;
    border-radius: 6px;
}
.company-name{
    text-decoration: none;
    color: black;
    font-size:20px;
    display: flex;
    align-items: center;
}
.company-link-icon{ 
    margin-left: 6px;
    font-size: 20px;
}
.work-logo{
    height: 80px;
}
.work-info{
    display: flex;
    flex-direction: column;
}
.work-dates{
    color: gray;
}
.work-desc{
    text-align: left;
    max-width: 80%;
}
@media only screen and (max-width:720px) {
    .work-card{
        width: 100%;
    }
    .company-name{
        font-size: 16px;
    }
    .company-link-icon{ 
        margin-left: 6px;
        font-size: 16px;
    }
}