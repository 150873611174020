.card-container{
    width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.image-container{
    background-position: center;
    background-size: cover;
    height: 200px;
}
.card-content{
    margin: 1rem;
}

h3, p{
    /* resseting default values */
    margin: 0;
    padding: 0;
}
.card-title{
    margin-bottom: 0.5rem;
}
.project-tags-2{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    margin-top: auto;
}
.tag{
    padding: 4px 8px;
    background-color: rgb(215, 223, 220);
    margin-right: 6px;
    margin-bottom: 4px;
    border-radius: 4px;
}
@media only screen and (max-width: 720px) {
    .card-container{
        margin-bottom: 16px;
    }
    
}